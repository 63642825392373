<template>
  <v-app>
    <!-- <v-app-bar app></v-app-bar> -->
    <v-main>
      <!-- <v-container >
        <v-row>
          <v-col> -->
            <Stepper 
              :text_settings=this.text_settings
            />
          <!-- </v-col>
        </v-row>
      </v-container> -->
    </v-main>
    <Footer
      :text_settings=this.text_settings
    />
  </v-app>
</template>

<script>
import Stepper from './components/Stepper';
import Footer from './components/Footer';
import { mapActions } from "vuex";

export default {
  name: 'App',
  components: {
    Stepper, Footer
  },
  methods: {
    ...mapActions([
      "get_text_setting"
    ]),
    prepare_text_setting: function() {
      this.get_text_setting().then((response) =>{
        this.text_settings = response
      })
    },
  },
  created () {
      document.title = "Testdrive Heroes - Demo Exchange Drive";
      this.prepare_text_setting();
  },
  data() {
    return {
      text_settings:""
    };
  },
};
</script>
