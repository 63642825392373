import axios from 'axios';

export const car_details = {
    get_car_type_model_details,
    get_details,
    get_car_details,
    get_available_slot,
    book_appointment,
    get_location,
    get_location_from_brand,
    get_brands_from_location,
    get_available_slot_consultation,
    book_appointment_consultation,
    consulation_appointment_type_disable,
    get_car_location,
    get_text_setting
};
async function book_appointment(post_data) {
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    };
    return axios.post("/api/method/carrental.api.book_appointment", post_data, requestOptions)
        .then(response => {

            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });
}
async function book_appointment_consultation(post_data) {
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    };
    // return axios.post("/api/method/carrental.api.book_appointment_consulation", post_data, requestOptions)
    return axios.post("/api/method/carrental.api.appointment_website_exchange", post_data, requestOptions)
        .then(response => {

            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });
}
async function get_details(car_type_details) {
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    return axios.get("/api/method/carrental.api.get_available_model_details", { params: car_type_details }, requestOptions)
        .then(response => {

            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });
}

async function get_car_details(car_details) {
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    return axios.get("/api/method/carrental.api.get_car_details", { params: car_details }, requestOptions)
        .then(response => {
            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });
}

async function get_available_slot(data) {
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    return axios.get("/api/method/carrental.api.get_available_slot", { params: data }, requestOptions)
        .then(response => {

            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });
}

async function get_available_slot_consultation(data) {
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    // https://demo-backend.testdrive-heroes.com/api/method/carrental.api.get_available_slot_website_car_exchange
    return axios.get("/api/method/carrental.api.get_available_slot_website_car_exchange", { params: data }, requestOptions)
        .then(response => {

            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });
}
async function get_car_location(data) {
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    // https://demo-backend.testdrive-heroes.com/api/method/carrental.api.get_location_from_location_code
    return axios.get("/api/method/carrental.api.get_location_from_location_code", { params: data }, requestOptions)
        .then(response => {

            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });
}
function get_car_type_model_details() {
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    };
    return axios.get("/api/method/carrental.api.get_car_type_model_details_new", requestOptions)
        .then(response => { return response.data.message; })
        .catch(function (response) {
            return handleResponse(response);
        })
}

// function get_location(car_data) {
//     let requestOptions = {
//         headers: {
//             'Content-Type': 'multipart/form-data',
//             'Accept': '*/*'
//         },
//     };
//     return axios.get("/api/method/carrental.api.get_location", { params: car_data },  requestOptions)
//         .then(response => { console.log(response); return response; })
//         .catch(function (response) {
//             return handleResponse(response);
//         })
// }

async function get_location(data) {
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    return axios.get("/api/method/carrental.api.get_location", { params: data }, requestOptions)
        .then(response => {

            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });
}

async function get_location_from_brand(data){
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    return axios.get("/api/method/carrental.api.get_location_from_brand_new", { params: data }, requestOptions)
        .then(response => {

            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });

}

async function get_brands_from_location(data){
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    return axios.get("/api/method/carrental.api.get_brands_from_location", { params: data }, requestOptions)
        .then(response => {

            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });

}

async function consulation_appointment_type_disable(data){
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    return axios.get("/api/method/carrental.api.consulation_appointment_type_disable",{ params: data }, requestOptions)
        .then(response => {

            return response.data.message;
        })
        .catch(function (response) {
            if (response.response.data.message) {
                return Promise.reject(response.response.data.message);
            } else {
                return Promise.reject("something went wrong, please try again");
            }
        });

}

function get_text_setting() {
    let requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    };
    //https://demo-backend.testdrive-heroes.com/api/method/carrental.api.get_website_exchange_text_setting
    return axios.get("/api/method/carrental.api.get_website_exchange_text_setting", requestOptions)
        .then(response => { return response.data.message; })
        .catch(function (response) {
            return handleResponse(response);
        })
}

function handleResponse(error) {
    if (error.response) {
        return Promise.reject("Something went wrong, please try again.");
    } else {
        if (error.data.message.message) {
            return Promise.reject(error.data.message.message);
        } else {
            return Promise.reject("Something went wrong, please try again.");
        }
    }
}
